import { template as template_e423be0c64494d439abdca2c0b91367c } from "@ember/template-compiler";
const WelcomeHeader = template_e423be0c64494d439abdca2c0b91367c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
