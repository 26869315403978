import { template as template_67a6ad34ebc54cd889b9cc08039d6d65 } from "@ember/template-compiler";
const FKLabel = template_67a6ad34ebc54cd889b9cc08039d6d65(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
