import { template as template_c525fc5d2aa74fb78c2c363b63877093 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c525fc5d2aa74fb78c2c363b63877093(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
